<template>
	<div class="container">
		<span class="slogan">粗门，就是好玩！</span>
    <div><a href="cumen://app.cumen.fun//?tabIndex=1">cumen://运动</a></div>
    <div><a href="cumen://app.cumen.fun//?tabIndex=2">cumen://消息</a></div>
    <div><a href="cumen://app.cumen.fun/create_post">cumen://发布活动</a></div>
    <div><a href="cumen://app.cumen.fun/edit_profile">cumen://编辑资料</a></div>

    <div><a href="https://app.cumen.fun/create_post">https://发布活动</a></div>
	</div>
</template>

<script>
export default {
	name: "cumenApp",
	components: {
	},
	data() {
		return {
			showGuide: false,
			isWechatBrowser: false,
			isIos: false,
			isAndroid: false,
		};
	},
	created() {
		const { userAgent } = window.navigator;
		this.isWechatBrowser =
			userAgent.toLowerCase().indexOf("micromessenger") !== -1;
		this.isIos = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
		this.isAndroid =
			userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;
		/* 默认打开引导浏览器提示的场景【设备：安卓/IOS，环境：微信浏览器】 */
		this.showGuide = this.isWechatBrowser;
	},
	methods: {
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.download {
	position: relative;
	@include column(flex-start, center);
}
</style>
